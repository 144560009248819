@import 'colors';
@import 'spacing';

/*
 * Generic button mixin for easy button styling
*/
@mixin button-bg(
  $bg: black,
  $bg-hover-color: white,
  $color: white,
  $hover-color: black,
  $border-color: transparent,
  $border-hover-color: transparent
) {
  background-color: $bg;
  color: $color;
  border: 2px solid $border-color;

  &:hover {
    background: $bg-hover-color;
    color: $hover-color;
    border: 2px solid $border-hover-color;
    transition: all 0.3s ease;
  }

  &:active {
    background: darken($bg, 25%);
  }
}

// Base button style

.ls-btn {
  color: white;
  text-decoration: none;
  padding: $s1 $s4;
  border-radius: 4px;
  font-weight: bold;
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
}

// Custom buttons

.ls-btn-primary {
  @include button-bg(color(primary), white, white, color(primary), transparent, color(primary));
}

.ls-btn-outline {
  @include button-bg(white, color(primary), color(primary), white, color(primary), color(primary));
}
.ls-btn-accent {
  @include button-bg(color(primary, dark), $purple, white, white, transparent, transparent);
}

.btn-warn {
  @include button-bg($red, white, white, $red, transparent, $red);
}

// Helper modifiers

.btn-small {
  transform: scale(0.8);
}

.btn-large {
  transform: scale(1.4);
}
