*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}
