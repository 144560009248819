@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import 'colors';

// Font families
$montserrat: montserrat, Arial, sans-serif;
$azo: azo-sans-web, sans-serif;
$figtree: 'Figtree', sans-serif;

// Font Weights
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

// Custom Typeography
.ls-font-header {
  font-family: $montserrat;
  font-size: clamp(1.8rem, 4.8vw, 3.5rem);
  font-weight: 300;
  color: $ls-header;
  text-align: center;
  margin: 0 auto;
}

.ls-font-sub-header {
  font-family: $montserrat;
  font-size: clamp(1.4rem, 3.2vw, 1.8rem);
  color: $ls-header;
  font-weight: bold;
}

.ls-font-body-bold {
  font-family: $azo;
}

.ls-font-body {
  font-family: $azo;
  line-height: 1.8rem;
  font-size: clamp(1rem, 2vw, 1.2rem);
  font-weight: 300;
}

.ls-font-slide-text {
  color: white;
  font-size: clamp(0.8rem, 3vw, 3.5rem);
}

.ls-font-slide-header {
  font-family: $montserrat;
  color: $ls-header;
  font-size: clamp(1.8rem, 3vw, 6rem);
  font-weight: 300;
}

// Font defaults

body,
button,
input {
  font-family: $montserrat;
}

html {
  font-size: 16px; // rem units are calculated against this value
}

h1,
h2,
h3,
h4 {
  font-size: 1rem;
  margin: 0;
}

// Text helpers

.bold {
  // font-weight: bold;
  font-weight: 500;
}

.center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

// Mixin helpers

@mixin font($font-size: 1, $font-weight: 400) {
  font-size: $font-size + rem;
  font-weight: $font-weight;
}

.ls-title {
  font-family: $montserrat;
  font-size: clamp(2rem, 3vw, 4rem);
  font-weight: 300;
  color: $ls-header;
  text-align: center;
  margin: 0 auto;
  padding: 0 2rem;
}

.ls-paragraph {
  font-family: $azo;
}

@mixin large-thin-purple-header() {
  color: color(primary);
  font-family: $montserrat;
  font-size: 2.4rem;
  font-weight: 300;
}

/*
 * Custom clamp function
*/
@function lsw-clamp($min: 1, $max: 1) {
  $MIN_VIEWPORT_WIDTH: 320;
  $MAX_VIEWPORT_WIDTH: 2560;

  $minInPx: calc($min * 16);
  $maxInPx: calc($max * 16);

  $maxInPxMinusMinInPx: calc($maxInPx - $minInPx);
  $maxViewportMinusMinViewport: calc($MAX_VIEWPORT_WIDTH - $MIN_VIEWPORT_WIDTH);

  $viewportValue: calc((100 * $maxInPxMinusMinInPx) / $maxViewportMinusMinViewport);

  $relativeValue: calc(
    ($MIN_VIEWPORT_WIDTH * $maxInPx - $MAX_VIEWPORT_WIDTH * $minInPx) / ($MIN_VIEWPORT_WIDTH - $MAX_VIEWPORT_WIDTH) / 16
  );

  @return clamp(#{$min}rem, #{$relativeValue}rem + #{$viewportValue}vw, #{$max}rem);
}
