// Transitions
$fade-in: fade 1s ease-out 0.8s forwards;
$btn-hover-fade: background-color 0.2s ease-in;

// Animations
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
