@import '~@angular/material/theming';

// Brand Colors
$ls-primary: #3a3372;
$ls-purple: #3a3372;

// Custom Palette
$ls-header: #393473;
$ls-grey: #434343;
$ls-black: #080a0a;
$ls-rich-purple: #480079;
$ls-orange: #fdbf4d;

// Generic Colors
$red: #db3b1f;
$orange: #db831f;
$yellow: #e9e50e;
$green: #69df45;
$blue: #1f86da;
$purple: #712b91;
$violet: #ee456c;

// Brand Colors
@function color($color-name, $color-modifier: 'base') {
  @return map-get(map-get($colors, $color-name), $color-modifier);
}

$colors: (
  primary: (
    base: $ls-purple,
    light: lighten($ls-purple, 15%),
    dark: darken($ls-purple, 15%),
    trans: transparentize($ls-purple, 0.5),
    faintEven: transparentize($ls-purple, 0.95),
    faintOdd: transparentize($ls-purple, 0.9),
    rich: $ls-rich-purple,
  ),
  accent: (
    base: $purple,
    light: lighten($purple, 15%),
    dark: darken($purple, 15%),
    trans: transparentize($purple, 0.5),
    faintEven: transparentize($purple, 0.95),
    faintOdd: transparentize($purple, 0.9),
  ),
  grey: (
    base: $ls-grey,
    light: lighten($ls-grey, 15%),
    lighter: lighten($ls-grey, 40%),
    dark: darken($ls-grey, 15%),
    trans: transparentize($ls-grey, 0.5),
  ),
  rose: (
    base: #dc536e,
  ),
  golden: (
    base: #f3c062,
  ),
  mauve: (
    base: #766ba6,
  ),
);

// Material

$primary: mat-palette($mat-indigo, 500);
$accent: mat-palette($mat-teal);
$warn: mat-palette($mat-red);
$theme: mat-light-theme($primary, $accent, $warn);

$custom-colors: (
  green: mat-color($mat-green, 700),
  red: mat-color($mat-red, 400),
);
